export default defineNuxtPlugin((nuxtApp) => {
  // nuxtApp.hook('app:rendered', (renderContext) => {
  //   const cookies = renderContext.event.req.headers.cookie

  //   if (cookies && cookies.includes('cka_auth')) {
  //     renderContext.event.res.setHeader('Cache-Control', 'no-store')
  //     renderContext.event.res.setHeader('Pragma', 'no-cache')
  //   } else {      
  //     renderContext.event.res.setHeader('Cache-Control', 'public, max-age=3600')
  //     renderContext.event.res.setHeader('Pragma', 'public')
  //   }
  // })
})